import { useState, useEffect } from 'react';
import moment from 'moment';
import { DrawerNovoContratoWrapper } from './styles';
import { DarkBackground } from '../CreateNewBusiness/styles';
import {
  ClickAwayListener,
  Fade,
  Radio,
  ThemeProvider,
  createTheme
} from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import { AiOutlineBars, AiOutlineUpload } from 'react-icons/ai';
import { MdFreeCancellation } from 'react-icons/md';
import { RiHome6Fill } from 'react-icons/ri';
import { HiTrash } from 'react-icons/hi';
import { useFieldArray, useForm } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@mui/material/Tooltip';
import { BsCheckLg } from 'react-icons/bs';
import toast from 'react-hot-toast';
import services from 'apps/broker/services';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const DrawerNovoContrato = ({
  setNovoContratoDrawer,
  isSelected,
  getNegociosData,
  seguradoras
}) => {
  const [transitionLeave, setTransitionLeave] = useState(false);
  const [seguradora, setSeguradora] = useState('');
  const [precificacao, setPrecificacao] = useState('Custo Médio');
  const [apolice, setApolice] = useState('');
  const [vingenciaInicial, setVigenciaInicial] = useState(new Date());
  const [mesAniversario, setMesAniversario] = useState('');
  const [diaVencimento, setDiaVencimento] = useState('');
  const [diaCorte, setDiaCorte] = useState('');
  const [formaCusteio, setFormaCusteio] = useState('');
  const [multaContratual, setMultaContratual] = useState('');
  const [coparticipacao, setCoparticipacao] = useState('0');
  const [detalhesCoparticipacao, setDetalhesCoparticipacao] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [dataCancelamento, setDataCancelamento] = useState('');
  const [aba, setAba] = useState('Dados Principais');
  const [files, setFiles] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isReopenContract, setIsReopenContract] = useState(false);
  const [reopenDate, setReopenDate] = useState(null);
  const [openEditDate, setOpenEditDate] = useState(false);

  const {
    register,
    control,
    watch,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      planos: [{ nome: '' }]
    }
  });

  useEffect(() => {
    if (isSelected?.data_renovacao) {
      setIsReopenContract(true);
      setReopenDate(new Date(isSelected.data_renovacao));
    }
  }, [isSelected]);

  const onChangeSelectToReopen = async (value) => {
    setIsReopenContract(value);
  };

  const onChangePickerToReopen = async (date) => {
    setReopenDate(date);
  };

  const {
    fields: planosField,
    append: planosAppend,
    remove: planosRemove
  } = useFieldArray({ control, name: 'planos' });

  const radioTipoClickHandler = (e) => {
    switch (e.target.name) {
      case 'precificacao':
        return setPrecificacao(e.target.value);

      case 'coparticipacao':
        return setCoparticipacao(e.target.value);

      default:
        break;
    }
  };

  const renderOptionsSeguradoras = () => {
    if (seguradoras?.length) {
      return seguradoras?.map((item) => (
        <option value={item.id}>{item.nome_completo.toLowerCase()}</option>
      ));
    }
  };

  const renderOptionsMesNiver = () => {
    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ];

    return meses.map((index) => (
      <option key={`mes_niver_${index}`} value={index}>
        {index}
      </option>
    ));
  };

  const renderOptionsDiaVenc = () => {
    let dias;

    if (mesAniversario === 'Fevereiro') {
      dias = Array.from(Array(29).keys()).map((i) => {
        if (i !== 0)
          return (
            <option key={i} value={i}>
              {i}
            </option>
          );
        return null;
      });
    } else if (
      mesAniversario === 'Abril' ||
      mesAniversario === 'Junho' ||
      mesAniversario === 'Setembro' ||
      mesAniversario === 'Novembro'
    ) {
      dias = Array.from(Array(31).keys()).map((i) => {
        if (i !== 0)
          return (
            <option key={i} value={i}>
              {i}
            </option>
          );
        return null;
      });
    } else {
      dias = Array.from(Array(32).keys()).map((i) => {
        if (i !== 0)
          return (
            <option key={i} value={i}>
              {i}
            </option>
          );
        return null;
      });
    }

    return dias;
  };

  const switchTitulosRender = () => {
    switch (aba) {
      case 'Dados Principais':
        return <span>Cadastro de novo benefício</span>;
      case 'Planos':
        return <span>Inclusão de planos</span>;
      case 'Documentos':
        return <span>Inclusão de documentos</span>;
      case 'Cancelamento':
        return <span>Cancelamento do contrato anterior</span>;
      default:
        break;
    }
  };

  const handleFile = (target) => {
    if (target.files?.length === 0 || target.files === null) {
      return;
    }
    setFiles((prevState) => [...prevState, ...target.files]);
  };

  const deleteFile = (index) => {
    const newArray = files;
    newArray.splice(index, 1);
    if (newArray.length === 0) {
      setFiles([]);
    }
    setFiles([...newArray]);
  };

  const switchAbasRender = () => {
    switch (aba) {
      case 'Dados Principais':
        return (
          <div className="container">
            <div className="primeira_coluna">
              <div className="div_default">
                <span className="input_title">Seguradora</span>
                <select
                  value={seguradora}
                  onChange={(e) => setSeguradora(e.target.value)}
                >
                  <option
                    disabled
                    value={''}
                    style={{ fontWeight: 'bold', textTransform: 'none' }}
                  >
                    Selecione
                  </option>
                  {renderOptionsSeguradoras()}
                </select>
              </div>
              <div className="div_default">
                <span className="input_title">Precificação</span>
                <div className="radio_div">
                  <div className="radio_container">
                    <ThemeProvider theme={theme}>
                      <Radio
                        color={'darkGenoaBlue'}
                        onClick={radioTipoClickHandler}
                        name="precificacao"
                        value={'Custo Médio'}
                        checked={precificacao === 'Custo Médio'}
                      />
                    </ThemeProvider>
                    <label>Custo Médio</label>
                  </div>
                  <div className="radio_container">
                    <ThemeProvider theme={theme}>
                      <Radio
                        color={'darkGenoaBlue'}
                        onClick={radioTipoClickHandler}
                        name="precificacao"
                        value={'Faixa Etária'}
                        checked={precificacao === 'Faixa Etária'}
                      />
                    </ThemeProvider>
                    <label>Faixa Etária</label>
                  </div>
                </div>
              </div>
              <div className="div_default div_input">
                <span className="input_title">Número da Apólice</span>
                <input
                  type="text"
                  value={apolice}
                  onChange={(e) => setApolice(e.target.value)}
                />
              </div>
              <div className="div_default div_input">
                <span className="input_title">Vigência Inicial</span>
                <div>
                  <ReactDatePicker
                    className="datepicker"
                    selected={vingenciaInicial}
                    dateFormat={'dd/MM/yyyy'}
                    locale={'ptBR'}
                    onChange={(date) => setVigenciaInicial(date)}
                  />
                </div>
              </div>
              <div className="div_default">
                <span className="input_title">
                  O contrato deve ser reaberto?
                </span>
                <select
                  value={isReopenContract ? 'Sim' : 'Não'}
                  onChange={(e) =>
                    onChangeSelectToReopen(e.target.value === 'Sim')
                  }
                >
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </select>

                {isReopenContract && (
                  <div className="details__reopen-date">
                    {openEditDate ? (
                      <ClickAwayListener
                        onClickAway={() => setOpenEditDate(false)}
                      >
                        <div>
                          <ReactDatePicker
                            className={`custom_datepicker ${reopenDate ? 'active' : ''
                              }`}
                            autoFocus={true}
                            selected={reopenDate}
                            locale={'ptBR'}
                            minDate={new Date()}
                            onChange={(date) => onChangePickerToReopen(date)}
                            onKeyDown={(e) => {
                              if (e?.key === 'Enter') {
                                setOpenEditDate(false);
                              }
                            }}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </ClickAwayListener>
                    ) : (
                      <span onClick={() => setOpenEditDate(true)}>
                        {reopenDate
                          ? moment(reopenDate).format('DD/MM/YYYY')
                          : 'Selecione a data'}
                      </span>
                    )}
                  </div>
                )}
              </div>

              <div className="div_default">
                <span className="input_title">Mês Aniversário</span>
                <select
                  value={mesAniversario}
                  onChange={(e) => setMesAniversario(e.target.value)}
                >
                  <option
                    disabled
                    value={''}
                    style={{ fontWeight: 'bold', textTransform: 'none' }}
                  >
                    Selecione
                  </option>
                  {renderOptionsMesNiver()}
                </select>
              </div>
              <div className="div_default">
                <span className="input_title">Dia de vencimento da fatura</span>
                <select
                  value={diaVencimento}
                  onChange={(e) => setDiaVencimento(e.target.value)}
                >
                  <option
                    disabled
                    value={''}
                    style={{ fontWeight: 'bold', textTransform: 'none' }}
                  >
                    Selecione
                  </option>
                  {renderOptionsDiaVenc()}
                </select>
              </div>
              <div className="div_default">
                <span className="input_title">Dia de Corte</span>
                <select
                  value={diaCorte}
                  onChange={(e) => setDiaCorte(e.target.value)}
                >
                  <option
                    disabled
                    value={''}
                    style={{ fontWeight: 'bold', textTransform: 'none' }}
                  >
                    Selecione
                  </option>
                  {renderOptionsDiaVenc()}
                </select>
              </div>
            </div>
            <div className="segunda_coluna">
              <div className="div_default">
                <span className="input_title">Forma de Custeio</span>
                <textarea
                  value={formaCusteio}
                  onChange={(e) => setFormaCusteio(e.target.value)}
                />
              </div>
              <div className="div_default">
                <span className="input_title">Multa Contratual</span>
                <textarea
                  value={multaContratual}
                  onChange={(e) => setMultaContratual(e.target.value)}
                />
              </div>
              {isSelected?.produto === 'Saúde' ? (
                <>
                  <div className="div_default">
                    <span className="input_title">Coparticipação</span>
                    <div className="radio_div">
                      <div className="radio_container">
                        <ThemeProvider theme={theme}>
                          <Radio
                            color={'darkGenoaBlue'}
                            onClick={radioTipoClickHandler}
                            name="coparticipacao"
                            value={'1'}
                            checked={coparticipacao === '1'}
                          />
                        </ThemeProvider>
                        <label>Sim</label>
                      </div>
                      <div className="radio_container">
                        <ThemeProvider theme={theme}>
                          <Radio
                            color={'darkGenoaBlue'}
                            onClick={radioTipoClickHandler}
                            name="coparticipacao"
                            value={'0'}
                            checked={coparticipacao === '0'}
                          />
                        </ThemeProvider>
                        <label>Não</label>
                      </div>
                    </div>
                  </div>
                  <div className="div_default">
                    <span className="input_title">
                      Detalhes de Coparticipação
                    </span>
                    <textarea
                      disabled={coparticipacao === '0'}
                      value={detalhesCoparticipacao}
                      onChange={(e) =>
                        setDetalhesCoparticipacao(e.target.value)
                      }
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        );
      case 'Planos':
        return (
          <div className="planos_wrapper">
            <div>
              <span>
                Coloque os planos que os beneficiários desse contrato poderão
                escolher:
              </span>
            </div>
            <div className="planos_container">
              {planosField?.map((item, index) => {
                return (
                  <div className="div_planos">
                    <input
                      placeholder="Ex.: TNQQ"
                      name={`planos${index}.nome`}
                      {...register(`planos.${index}.nome`, { required: false })}
                    />
                    <button className="btn_trash">
                      <HiTrash onClick={() => planosRemove(index)} />
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="container_btn">
              <button
                className="aba_btn"
                onClick={() => planosAppend({ nome: '' })}
              >
                + adicionar mais um plano
              </button>
            </div>
          </div>
        );
      case 'Documentos':
        return (
          <div className="doc_wrapper">
            <div>
              <span>
                Adicione os documentos e contratos que foram utilizados para
                fechar o contrato.
              </span>
            </div>
            <div className="doc_container">
              {files?.map((file, index) => {
                return (
                  <div className="file" key={index}>
                    <span>{file?.name}</span>
                    <button
                      className="btn-clear"
                      onClick={() => deleteFile(index)}
                    >
                      <CloseIcon size={13} />
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="container_btn">
              <button className="aba_btn">
                <label className="input-label">
                  <span>
                    <BiPlus /> Documentos
                  </span>
                  <input
                    className="input-file"
                    type="file"
                    onChange={({ target }) => handleFile(target)}
                    multiple
                  />
                </label>
              </button>
            </div>
          </div>
        );
      case 'Cancelamento': {
        return isSelected.tipo === 'Renovação' ? (
          <div className="cancel_wrapper">
            <div className="cancel_subtitle">
              <span>{isSelected.seguradora_nome}</span>
              {isSelected?.numero_apolice
                ? ` - Apólice: ${isSelected?.numero_apolice}`
                : null}
            </div>
            <div className="cancelamento_container">
              <div>Data de vigência final</div>
            </div>
            <div className="date_container">
              <div className="custom_datepicker">
                <div>
                  <ReactDatePicker
                    className="datepicker"
                    locale="ptBR"
                    minDate={new Date()}
                    selected={dataCancelamento}
                    onChange={(date) => setDataCancelamento(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <div className="button_container">
                <button onClick={() => sendDataCancelamentoHandle()}>
                  Cancelar contrato
                </button>
                {confirmation ? (
                  <div className="check_confirm">
                    <BsCheckLg />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null;
      }

      default:
        break;
    }
  };

  const sendDataCancelamentoHandle = async () => {
    if (dataCancelamento) {
      const body = {
        negocio_id: isSelected.id,
        contrato_id: isSelected.contrato_id,
        vigencia_final: new Date(dataCancelamento)
      };

      const response =
        await services.insuranceCompanies.updateContractCancelByBusinessId(
          body
        );

      if (response.status === 200) {
        setConfirmation(true);
        toast.dismiss();
        toast.success('Data de cancelamento incluído!');
        getNegociosData();
      } else {
        toast.dismiss();
        toast.error('Algo deu errado, tente novamente.');
      }
    } else {
      toast.dismiss();
      toast.error('Inserir data de vigência final');
    }
  };

  const createNovoBeneficioHandler = async () => {
    const formData = new FormData();

    const body = {
      estipulante_id: isSelected?.estipulante_id,
      lead_potencial_id: isSelected?.lead_potencial_id,
      produto_id: isSelected?.produto_id,
      seguradora_id: seguradora,
      reabertura_contrato: reopenDate ? reopenDate : null,
      precificacao,
      apolice,
      vingenciaInicial,
      mesAniversario,
      diaVencimento,
      diaCorte,
      formaCusteio,
      multaContratual,
      planosField: getValues('planos'),
      negocio_id: isSelected.id
    };

    if (isSelected?.produto === 'Saúde') {
      body.coparticipacao = coparticipacao === 'true' ? true : false;
      if (body.coparticipacao) {
        body.detalhesCoparticipacao = detalhesCoparticipacao;
      } else {
        body.detalhesCoparticipacao = null;
      }
    } else {
      body.coparticipacao = false;
      body.detalhesCoparticipacao = null;
    }

    formData.append('body', JSON.stringify(body));

    files.forEach((file, index) => {
      formData.append(index, file);
    });

    // for (const [key, value] of formData) {
    //   // console.log(key, value)
    // }

    const response =
      await services.insuranceCompanies.createNewContractByBusinessId(formData);

    if (response.status === 201) {
      toast.dismiss();
      toast.success('Novo contrato criado com sucesso!');
      getNegociosData();
      setNovoContratoDrawer(false);
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente.');
    }
  };

  useEffect(() => {
    if (isSelected.tipo === 'Novo') {
      if (
        !seguradora ||
        !apolice ||
        !mesAniversario ||
        !diaVencimento ||
        !diaCorte ||
        !formaCusteio ||
        !multaContratual
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      if (
        !seguradora ||
        !apolice ||
        !mesAniversario ||
        !diaVencimento ||
        !diaCorte ||
        !formaCusteio ||
        !multaContratual ||
        !dataCancelamento
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [
    seguradora,
    apolice,
    mesAniversario,
    diaVencimento,
    diaCorte,
    formaCusteio,
    multaContratual,
    dataCancelamento
  ]);

  useEffect(() => { }, [mesAniversario]);

  return (
    <DarkBackground
      style={{
        animation: transitionLeave ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          setTransitionLeave(true);
          setTimeout(() => {
            setNovoContratoDrawer(false);
          }, 200);
        }}
      >
        <DrawerNovoContratoWrapper className={transitionLeave ? 'leave' : ''}>
          <div className="title">
            {switchTitulosRender()}

            <div className="btn_container">
              <Tooltip
                title="Dados Principais"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 300 }}
                arrow
              >
                <button
                  className={`aba_btn ${aba === 'Dados Principais' ? 'selected' : ''
                    }`}
                  onClick={() => setAba('Dados Principais')}
                >
                  <RiHome6Fill />
                </button>
              </Tooltip>
              <Tooltip
                title="Planos"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 300 }}
                arrow
              >
                <button
                  className={`aba_btn ${aba === 'Planos' ? 'selected' : ''}`}
                  onClick={() => setAba('Planos')}
                >
                  <AiOutlineBars />
                </button>
              </Tooltip>
              <Tooltip
                title="Documentos"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 300 }}
                placement={isSelected.tipo === 'Novo' ? 'bottom-end' : 'bottom'}
                arrow
              >
                <button
                  className={`aba_btn ${aba === 'Documentos' ? 'selected' : ''
                    }`}
                  onClick={() => setAba('Documentos')}
                >
                  <AiOutlineUpload />
                </button>
              </Tooltip>
              {isSelected.tipo === 'Renovação' ? (
                <Tooltip
                  title="Cancelamento"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 300 }}
                  placement="bottom-end"
                  arrow
                >
                  <button
                    className={`aba_btn ${aba === 'Cancelamento' ? 'selected' : ''
                      }`}
                    onClick={() => setAba('Cancelamento')}
                  >
                    <MdFreeCancellation />
                  </button>
                </Tooltip>
              ) : null}
            </div>
          </div>
          {switchAbasRender()}
          <div className="container_btn_enviar">
            <button
              className={`btn_enviar ${disabled ? 'disabled' : ''}`}
              onClick={disabled ? () => { } : () => createNovoBeneficioHandler()}
            >
              Enviar
            </button>
          </div>
        </DrawerNovoContratoWrapper>
      </ClickAwayListener>
    </DarkBackground>
  );
};

export default DrawerNovoContrato;
