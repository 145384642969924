import React from 'react';
import { Wrapper } from './styles';
import { BiMale } from 'react-icons/bi';
import moment from 'moment-timezone';
import { AiOutlineCreditCard, AiOutlineMinus } from 'react-icons/ai';
import { GiHealthNormal } from 'react-icons/gi';
import { FaTooth } from 'react-icons/fa';
import { BsFillCalendarEventFill } from 'react-icons/bs';

const DrawerBeneficiariesContent = ({ beneficiario, groupFamily }) => {
  const seguros = {
    1: <GiHealthNormal size={16} />,
    2: <FaTooth size={16} />,
    3: <BiMale size={16} />
  };

  const renderHealthPlanInfo = () => {
    const filteredHealth = beneficiario.contratos.filter(
      (item) => item.produto_id === 1
    );

    return (
      <>
        {filteredHealth.map((contrato, index) => {
          return (
            <div className="info-section" key={index}>
              <div className="data-info">
                <div>
                  {seguros[contrato.produto_id]}
                  <span className="info-plano">{contrato.seguradora_nome}</span>
                  <span className="info-plano">
                    <AiOutlineMinus size={10} />
                  </span>
                  <span className="info-plano">{contrato.tipo_plano}</span>
                </div>
              </div>
              <div className="data-info">
                {seguros[contrato.produto_id]}
                <span className="info-plano">Apólice</span>
                <span className="info-plano">{contrato?.numero_apolice}</span>
              </div>
              <div className="data-info">
                <div>
                  <AiOutlineCreditCard size={18} />
                  <span className="info-plano">
                    {contrato?.numero_carteirinha}
                  </span>
                </div>
              </div>
              <div className="data-info">
                <div>
                  {contrato.data_entrada_formatada ? (
                    <>
                      <BsFillCalendarEventFill />
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'Avenir Next GEO W05 Bold',
                          fontSize: '16px'
                        }}
                      >
                        Data de entrada:{' '}
                      </span>
                      <span className="info-plano">
                        {contrato.data_entrada_formatada}
                      </span>
                    </>
                  ) : (
                    <BsFillCalendarEventFill />
                  )}
                </div>
                <div>
                  {contrato.contrato_beneficiario_data_exclusao ? (
                    <>
                      <BsFillCalendarEventFill />
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'Avenir Next GEO W05 Bold',
                          fontSize: '16px'
                        }}
                      >
                        Data de exclusão:{' '}
                      </span>
                      <span className="info-plano">
                        {moment(contrato.contrato_beneficiario_data_exclusao)
                          .utc()
                          .format('DD/MM/YYYY')}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderDentalPlanInfo = () => {
    const filteredOdonto = beneficiario.contratos.filter(
      (item) => item.produto_id === 2
    );

    return (
      <>
        {filteredOdonto.map((contrato, index) => {
          return (
            <div className="info-section" key={index}>
              <div className="data-info">
                <div>
                  {seguros[contrato.produto_id]}
                  <span className="info-plano">{contrato.seguradora_nome}</span>
                  <span className="info-plano">
                    <AiOutlineMinus size={10} />
                  </span>
                  <span className="info-plano">{contrato.tipo_plano}</span>
                </div>
              </div>
              <div className="data-info">
                {seguros[contrato.produto_id]}
                <span className="info-plano">Apólice</span>
                <span className="info-plano">{contrato?.numero_apolice}</span>
              </div>
              <div className="data-info">
                <div>
                  <AiOutlineCreditCard size={18} />
                  <span className="info-plano">
                    {contrato?.numero_carteirinha}
                  </span>
                </div>
              </div>
              <div className="data-info">
                <div>
                  {contrato.data_entrada_formatada ? (
                    <>
                      <BsFillCalendarEventFill />
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'Avenir Next GEO W05 Bold',
                          fontSize: '16px'
                        }}
                      >
                        Data de entrada:{' '}
                      </span>
                      <span className="info-plano">
                        {contrato.data_entrada_formatada}
                      </span>
                    </>
                  ) : (
                    <BsFillCalendarEventFill />
                  )}
                </div>
                <div>
                  {contrato.contrato_beneficiario_data_exclusao ? (
                    <>
                      <BsFillCalendarEventFill />
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontFamily: 'Avenir Next GEO W05 Bold',
                          fontSize: '16px'
                        }}
                      >
                        Data de exclusão:{' '}
                      </span>
                      <span className="info-plano">
                        {moment(contrato.contrato_beneficiario_data_exclusao)
                          .utc()
                          .format('DD/MM/YYYY')}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Wrapper>
      <header>
        <div className="headerUser">
          <div className="nameUser">
            <div className="user-company">
              <span className="header-title-name">{beneficiario.nome}</span>
              <div className="header-title-second-row">
                <span className="header-title-estipulante">
                  {beneficiario.razao_social}
                </span>
                <span className="header-title-hifen-space">
                  {' '}
                  <AiOutlineMinus size={10} />
                </span>
                <span className="header-title-estipulante">
                  {beneficiario.beneficiario_tipo}
                </span>
                <span className="header-title-hifen-space">
                  {' '}
                  <AiOutlineMinus size={10} />
                </span>
                <span className="header-title-estipulante">
                  ID: {beneficiario.beneficiario_id}
                </span>
                {beneficiario.idade ? (
                  <>
                    <span className="header-title-hifen-space">
                      {' '}
                      <AiOutlineMinus size={10} />
                    </span>
                    <span className="header-title-estipulante">
                      {beneficiario.idade} anos
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="infos">
        <div className="infos-section-title-container">
          <span className="infos-section-title">Benefícios</span>
        </div>
        {renderHealthPlanInfo()}
        {renderDentalPlanInfo()}
        <div className="info-section">
          <div className="infos-section-title-container">
            <span className="infos-section-title">Outras Informações</span>
          </div>
          <ul className="list-infos">
            <li>
              <span className="list-info-title">CPF:</span>
              <span className="list-info-value">{beneficiario.cpf}</span>
            </li>
            <li>
              <span className="list-info-title">Data de Vínculo:</span>
              <span className="list-info-value">
                {beneficiario?.data_vinculo_formatada}
              </span>
            </li>
            <li>
              <span className="list-info-title">Data de Nascimento:</span>
              <span className="list-info-value">
                {beneficiario?.data_nascimento_formatada}
              </span>
            </li>
            <li>
              <span className="list-info-title">Sexo:</span>
              <span className="list-info-value">{beneficiario?.sexo}</span>
            </li>
            {beneficiario.beneficiario_tipo === 'Dependente' ? (
              <li>
                <span className="list-info-title">Grau Parentesco:</span>
                <span className="list-info-value">
                  {beneficiario?.grau_parentesco}
                </span>
              </li>
            ) : null}
            <li>
              <span className="list-info-title">E-mail:</span>
              <span className="list-info-value">{beneficiario?.email}</span>
            </li>
            <li>
              <span className="list-info-title">Telefone:</span>
              <span className="list-info-value">{beneficiario?.telefone}</span>
            </li>
          </ul>
        </div>
        {beneficiario.beneficiario_tipo === 'Titular' ? (
          groupFamily.length > 0 ? (
            <div className="info-section">
              <div className="infos-section-title-container">
                <span className="infos-section-title">Grupo Familiar</span>
              </div>
              <ul className="list-infos">
                {groupFamily?.map((dependente) => (
                  <li>
                    <span className="list-info-title">Dependente:</span>
                    <span className="list-info-value">{`${dependente.nome} ${
                      dependente.grau_parentesco
                        ? `- Grau Parentesco: ${dependente.grau_parentesco}`
                        : ''
                    }`}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null
        ) : (
          <div className="info-section">
            <div className="infos-section-title-container">
              <span className="infos-section-title">Grupo Familiar</span>
            </div>
            <ul className="list-infos">
              {groupFamily?.map((beneficiary) => {
                return beneficiary.tipo === 'Titular' ? (
                  <li>
                    <span className="list-info-title">Titular:</span>
                    <span className="list-info-value">
                      {beneficiary.tipo === 'Titular'
                        ? `${beneficiary.nome}`
                        : null}
                    </span>
                  </li>
                ) : (
                  <li>
                    <span className="list-info-title">Dependente:</span>
                    <span className="list-info-value">
                      {beneficiary.tipo === 'Dependente'
                        ? `${beneficiary.nome}`
                        : null}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </section>
    </Wrapper>
  );
};

export default DrawerBeneficiariesContent;
