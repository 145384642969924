import { useState, useEffect } from 'react';
import { FaClock, FaTooth } from 'react-icons/fa';
import { MdEditOff } from 'react-icons/md';
import { BiMale } from 'react-icons/bi';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@mui/material/Box';
import { BsFillCalendarEventFill } from 'react-icons/bs';
import { CircularProgress } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import { VscChromeClose, VscCheck } from 'react-icons/vsc';
import { GiHealthNormal } from 'react-icons/gi';
import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineCreditCard
} from 'react-icons/ai';
import services from 'apps/broker/services';
import RemoveBeneficiary from './RemoveBeneficiary';
import BeneficiaryDetails from './BeneficiaryDetails';
import EditBeneficiary from './EditBeneficiary';
import Button from 'components/DS/Button';
import moment from 'moment';
import './style.scss';

const seguros = {
  1: <GiHealthNormal className="expanded-content-icon" size={16} />,
  2: <FaTooth className="expanded-content-icon" size={16} />,
  3: <BiMale className="expanded-content-icon" size={16} />
};

const itemsPerPage = 15;

const TableResult = ({
  loading,
  estipulanteAndSubsSelected,
  addFilters,
  beneficiarios,
  setAllEstipulantes,
  estipulantePlanoSelected,
  setBeneficiarioToOpenDrawer,
  setBeneficiariosFilter,
  setBeneficiarioSelected,
  beneficiariosFilter,
  getBeneficiarios,
  setLoading,
  setAddFilters,
  setEstipulantePlanoSelected,
  setSelectedCheckboxes,
  selectedCheckboxes,
  setBeneficiarios,
  currentPage,
  setCurrentPage,
  setMasterCheckboxChecked,
  masterCheckboxChecked,
  toggleDrawer,
  setEstipulanteSelected,
  setEstipulanteAndSubsSelected,
  setAllEstipulanteAndSubs,
  estipulanteSelected
}) => {
  const [editMode, setEditMode] = useState(false);
  const [anchorElDelete, setAnchorElDeletePopover] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const handleClose = () => {
    setAnchorElDeletePopover(null);
  };

  const openList = (panel) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const filterBeneficiarios = () => {
    const beneficiariosFiltrados = beneficiarios
      .filter((beneficiario) =>
        estipulanteAndSubsSelected.some(
          (item) => item === beneficiario.estipulante_id
        )
      )
      .filter((beneficiario) => {
        if (addFilters.status === 'Todos') {
          return beneficiario;
        }
        return beneficiario.status === addFilters.status;
      })
      .filter((beneficiario) =>
        beneficiario.nome
          .toLowerCase()
          .includes(addFilters.nomeBeneficiario.toLowerCase())
      )
      .filter((beneficiario) => {
        const beneficiarioHasPlano = beneficiario.contratos.some((contrato) =>
          estipulantePlanoSelected.some((item) => item === contrato.contrato_id)
        );
        return beneficiarioHasPlano;
      });

    setCurrentPage(1);
    setBeneficiariosFilter(beneficiariosFiltrados);
  };

  function reduceEstipulantes2(data) {
    const estipulante = data?.sort((a, b) => {
      if (b.razao_social < a.razao_social) {
        return 1;
      }
      return -1;
    });

    return estipulante;
  }

  async function getEstipulantesAndSubBeneficiarios() {
    const response = await services.policyOwner.getPolicyOwnerAndSub();

    setAllEstipulantes(reduceEstipulantes2(response.data));
    const defaultEstipulante = reduceEstipulantes2(response.data)[0];

    setEstipulanteSelected(defaultEstipulante);

    let estipAndSubsSelected = [defaultEstipulante.id];

    defaultEstipulante.subEstipulantes.forEach((sub) => {
      estipAndSubsSelected = [...estipAndSubsSelected, sub.id];
    });

    setEstipulanteAndSubsSelected(estipAndSubsSelected);
    setAllEstipulanteAndSubs(estipAndSubsSelected);

    const allPlanosSelected = defaultEstipulante.contratos.map(
      (item) => item.contrato_id
    );

    setEstipulantePlanoSelected(allPlanosSelected);
    await getBeneficiarios(defaultEstipulante.id);

    setLoading(false);
  }

  const editModeClickHandler = () => {
    setEditMode((prevState) => !prevState);
  };

  const clickMasterCheckboxHandler = ({ target }) => {
    const checked = target.checked;

    if (checked) {
      const allIdFromBeneficiarios = beneficiariosFilter.map(
        (beneficiario) => beneficiario.beneficiario_id
      );
      setSelectedCheckboxes(allIdFromBeneficiarios);
      setMasterCheckboxChecked((prevstate) => !prevstate);
    }

    if (!checked) {
      setSelectedCheckboxes([]);
      setMasterCheckboxChecked((prevstate) => !prevstate);
    }
  };

  const filterAfterDelete = () => {
    const dataFilteredAfterDelete = beneficiariosFilter.filter(
      (beneficiario) => {
        const isBeneficiarioDeleted = selectedCheckboxes.some((id) => {
          if (id !== beneficiario.beneficiario_id) return false;
          return true;
        });
        if (isBeneficiarioDeleted) return false;
        return true;
      }
    );
    const dataAfterDelete = beneficiarios.filter((beneficiario) => {
      const isBeneficiarioDeleted = selectedCheckboxes.some((id) => {
        if (id !== beneficiario.beneficiario_id) return false;
        return true;
      });
      if (isBeneficiarioDeleted) return false;
      return true;
    });
    setBeneficiariosFilter(dataFilteredAfterDelete);
    setBeneficiarios(dataAfterDelete);
  };

  const popupPositiveClickHandler = async () => {
    await services.beneficiaries.deleteBeneficiariesSelected(
      selectedCheckboxes
    );

    setAnchorElDeletePopover(null);
    setEditMode(false);
    filterAfterDelete();
  };

  const deleteDrawerToggleHandler = (target) => {
    setAnchorElDeletePopover((prevState) => (prevState ? null : target));
  };

  const handleDisplayMessage = () => {
    if (selectedCheckboxes.length > 0) {
      if (selectedCheckboxes.length === 1) {
        return 'Você quer realmente excluir o item selecionado?';
      }

      return 'Você quer realmente excluir os itens selecionados?';
    }
    return 'Selecione pelo menos um item para excluir';
  };

  const popupNegativeClickHandler = () => {
    setAnchorElDeletePopover(null);
  };

  const clickDeleteEstipulantesHandler = ({ target }) => {
    deleteDrawerToggleHandler(target);
  };

  const clickUniqueCheckboxHandler = ({ target }) => {
    const checked = target.checked;
    const beneficiarioId = Number(target.name);

    if (checked) {
      setSelectedCheckboxes((prevState) => [...prevState, beneficiarioId]);
    }

    if (!checked) {
      setSelectedCheckboxes((prevState) => {
        const filter = prevState.filter(
          (elementName) => elementName !== beneficiarioId
        );
        return filter;
      });
    }
  };

  const renderPlanoDeSaudeIcon = (beneficiario) => {
    const contratosSaude = beneficiario.contratos.filter(
      (item) => item.produto_id === 1
    );
    const maisRecenteSaude = contratosSaude
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const movimentacaoAgendada = maisRecenteSaude?.contrato_agendado_id;

    if (maisRecenteSaude) {
      const isPlanoDeSaudeActive =
        maisRecenteSaude.contrato_beneficiario_status === 'Ativo';
      if (isPlanoDeSaudeActive) {
        const isPlanoDeSaudeInRemoval = maisRecenteSaude.movimentacao_remocao;
        if (isPlanoDeSaudeInRemoval) {
          return (
            <span className="in-removal-icon">
              <GiHealthNormal />
            </span>
          );
        }
        if (movimentacaoAgendada) {
          return (
            <span className="active-icon">
              <GiHealthNormal />
              <FaClock className="calendar-icon-broker" />
            </span>
          );
        }
        return (
          <span className="active-icon">
            <GiHealthNormal />
          </span>
        );
      } else {
        return (
          <span className="inactive-icon">
            <GiHealthNormal />
          </span>
        );
      }
    }
    return <span></span>;
  };

  const renderPlanoOdontologicoIcon = (beneficiario) => {
    const contratosOdonto = beneficiario.contratos.filter(
      (item) => item.produto_id === 2
    );
    const maisRecenteOdonto = contratosOdonto
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const movimentacaoAgendada = maisRecenteOdonto?.contrato_agendado_id;

    if (maisRecenteOdonto) {
      const isPlanoOdontologicoActive =
        maisRecenteOdonto.contrato_beneficiario_status === 'Ativo';
      if (isPlanoOdontologicoActive) {
        const isPlanoOdontologicoInRemoval =
          maisRecenteOdonto.movimentacao_remocao;
        if (isPlanoOdontologicoInRemoval) {
          return (
            <span className="in-removal-icon">
              <FaTooth />
            </span>
          );
        }
        if (movimentacaoAgendada) {
          return (
            <span className="active-icon">
              <GiHealthNormal />
              <FaClock className="calendar-icon-broker" />
            </span>
          );
        }
        return (
          <span className="active-icon">
            <FaTooth />
          </span>
        );
      } else {
        return (
          <span className="inactive-icon">
            <FaTooth />
          </span>
        );
      }
    }
    return <span></span>;
  };

  const renderBeneficiarioPlanosInfo = (beneficiario) => {
    const contratosSaude = beneficiario.contratos.filter(
      (item) => item.produto_id === 1
    );
    const maisRecenteSaude = contratosSaude
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosOdonto = beneficiario.contratos.filter(
      (item) => item.produto_id === 2
    );
    const maisRecenteOdonto = contratosOdonto
      .sort(
        (a, b) =>
          Number(new Date(b.contrato_beneficiario_created_at)) -
          Number(new Date(a.contrato_beneficiario_created_at))
      )
      .shift();

    const contratosMaisRecentes = [maisRecenteSaude, maisRecenteOdonto];

    return (
      <>
        {contratosMaisRecentes.map((contrato) => {
          if (
            !contrato ||
            contrato.contrato_beneficiario_status !== addFilters['status']
          ) {
            return <div></div>;
          }
          return (
            <div className="broker-beneficiaries-tbody-expanded-container">
              <div className="broker-beneficiaries-tbody-expanded-content">
                {seguros[contrato.produto_id]}
                {contrato.numero_apolice ? (
                  <span>
                    {contrato.seguradora_nome} ({contrato.numero_apolice}) -
                    {contrato.tipo_plano}
                  </span>
                ) : (
                  <span>
                    {contrato.seguradora_nome} - {contrato.tipo_plano}
                  </span>
                )}
              </div>
              <div className="broker-beneficiaries-tbody-expanded-content">
                <AiOutlineCreditCard
                  className="expanded-content-icon"
                  size={18}
                />
                <span>{contrato?.numero_carteirinha}</span>
              </div>
              <div className="broker-beneficiaries-tbody-expanded-content">
                <BsFillCalendarEventFill
                  className="expanded-content-icon"
                  size={18}
                />
                <span>{contrato?.data_entrada_formatada}</span>
              </div>
              {contrato?.data_agendada ? (
                <div className="movimentacao-content-broker">
                  <div className="movimentacao-content-title-broker">
                    <span>Envio de exclusão agendado</span>
                  </div>
                  <div className="movimentacao-info-broker">
                    <FaClock
                      className="expanded-content-icon expanded-content-icon--clock"
                      size={18}
                    />
                    <span>
                      {moment(contrato?.data_agendada).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        setCurrentPage((prevState) => prevState + 1);
      }
    });

    intersectionObserver.observe(document.querySelector('#sentinela'));
    return () => intersectionObserver.disconnect();
  }, []);

  useEffect(() => {
    filterBeneficiarios();
  }, [estipulanteAndSubsSelected, addFilters, estipulantePlanoSelected]);

  useEffect(() => {
    getEstipulantesAndSubBeneficiarios();
  }, []);

  return (
    <div className="broker-beneficiaries-table">
      <div className="broker-beneficiaries-theader">
        <div className="broker-beneficiaries-table__checkbox-on-table">
          {editMode === false ? (
            <BiEdit
              size={18}
              className="button-edit-on-table"
              onClick={editModeClickHandler}
            />
          ) : (
            <Checkbox
              size="small"
              className="checkbox"
              sx={{
                color: '#1E2552',
                '&.Mui-checked': {
                  color: '#1E2552'
                },
                '&.MuiCheckbox-indeterminate': {
                  color: '#1E2552'
                }
              }}
              onChange={clickMasterCheckboxHandler}
              indeterminate={
                selectedCheckboxes.length > 0 &&
                selectedCheckboxes.length < beneficiariosFilter.length
              }
              checked={
                beneficiariosFilter.length === selectedCheckboxes.length &&
                masterCheckboxChecked
              }
            />
          )}
        </div>
        <div className="broker-beneficiaries-theader__th">Nome</div>
        <div className="broker-beneficiaries-theader__th">Tipo</div>
        <div className="broker-beneficiaries-theader__th">Vínculo</div>
        <div className="broker-beneficiaries-theader__th">
          Estipulante ou Sub Estipulante
        </div>
        <div className="broker-beneficiaries-theader__th">Idade</div>
        <div className="broker-beneficiaries-theader__th">Benefícios</div>
        <div className="broker-beneficiaries-theader__th">Status</div>
        <div></div>
        {editMode === true ? (
          <div className="broker-beneficiaries-table__edit-buttons">
            <MdEditOff size={22} onClick={editModeClickHandler} />
            <RiDeleteBin6Fill
              size={22}
              onClick={clickDeleteEstipulantesHandler}
            />
            <Popper
              id={'id'}
              open={Boolean(anchorElDelete)}
              anchorEl={anchorElDelete}
              placement="top-end"
              transition
              disablePortal
            >
              <Box>
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper
                    elevation={3}
                    className="paper-popup-delete-confirmation"
                  >
                    <span className="popup-message">
                      {handleDisplayMessage()}
                    </span>
                    {selectedCheckboxes.length > 0 ? (
                      <div
                        className="popup-options-container"
                        onClick={popupPositiveClickHandler}
                      >
                        <span className="popup-options">
                          <VscCheck className="check-icon" size={12} />
                          Sim
                        </span>
                        <span
                          className="popup-options"
                          onClick={popupNegativeClickHandler}
                        >
                          <VscChromeClose className="cross-icon" size={12} />
                          Não
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </Paper>
                </ClickAwayListener>
              </Box>
            </Popper>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {loading ? (
        <div className="loading">
          <CircularProgress
            size={110}
            thickness={2.5}
            className="loading-spinner"
          />
          <span>Carregando Beneficiarios</span>
        </div>
      ) : (
        <>
          {beneficiariosFilter
            ?.slice(0, currentPage * itemsPerPage)
            ?.map((beneficiario) => {
              return (
                <div>
                  <div
                    className={`${
                      expanded === beneficiario.beneficiario_id
                        ? 'broker-beneficiaries-tbody--expanded'
                        : 'broker-beneficiaries-tbody'
                    }`}
                  >
                    <div>
                      {editMode === true ? (
                        <Checkbox
                          size="small"
                          name={beneficiario.beneficiario_id}
                          sx={{
                            color: '#1E2552',
                            '&.Mui-checked': {
                              color: '#1E2552'
                            }
                          }}
                          onChange={clickUniqueCheckboxHandler}
                          checked={selectedCheckboxes.some((checkboxName) => {
                            return (
                              checkboxName === beneficiario.beneficiario_id
                            );
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="broker-beneficiaries-tbody__td">
                      {beneficiario.nome}
                    </div>
                    <div className="broker-beneficiaries-tbody__td">
                      {beneficiario.beneficiario_tipo}
                    </div>
                    <div className="broker-beneficiaries-tbody__td">
                      {beneficiario.vinculo}
                    </div>
                    <div className="broker-beneficiaries-tbody__td">
                      {beneficiario.razao_social}
                    </div>
                    <div className="broker-beneficiaries-tbody__td">
                      {beneficiario.idade}
                    </div>
                    <div className="broker-beneficiaries-tbody__td-icon">
                      {renderPlanoDeSaudeIcon(beneficiario)}
                      {renderPlanoOdontologicoIcon(beneficiario)}
                    </div>
                    <div className="broker-beneficiaries-tbody__td">
                      {beneficiario.status}
                    </div>
                    <div>
                      <RemoveBeneficiary beneficiario={beneficiario} />
                    </div>
                    <div>
                      <Button
                        variant="transparent"
                        size="medium"
                        onClick={() => openList(beneficiario.beneficiario_id)}
                      >
                        {expanded === beneficiario.beneficiario_id ? (
                          <AiFillCaretUp size={20} />
                        ) : (
                          <AiFillCaretDown size={20} />
                        )}
                      </Button>
                    </div>

                    <div>
                      <BeneficiaryDetails beneficiario={beneficiario} />
                    </div>
                  </div>
                  {expanded === beneficiario.beneficiario_id ? (
                    <div className="broker-beneficiaries-tbody-expanded broker-beneficiaries-tbody-expanded--animeLeft">
                      {renderBeneficiarioPlanosInfo(beneficiario)}

                      <div className="broker-beneficiaries-tbody-expanded-edit">
                        <EditBeneficiary
                          beneficiario={beneficiario}
                          setBeneficiarios={setBeneficiarios}
                          setAddFilters={setAddFilters}
                          setBeneficiariosFilter={setBeneficiariosFilter}
                          addFilters={addFilters}
                          estipulanteSelected={estipulanteSelected}
                          getBeneficiarios={getBeneficiarios}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
        </>
      )}
      <div id={loading ? 'sentinela-loading' : 'sentinela'}></div>
    </div>
  );
};

export default TableResult;
