import { useState } from 'react';
import { Box, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import { BsFilter } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import Top10PoliciesOwnersLifetime from '../Top10LifetimeTable';
import FinancialGraphic from '../FinancialGraphic';
import './style.scss';
import moment from 'moment';

const FinancialGeneralInformation = ({
  commissionMonth,
  commissionYear,
  lifetimePolicyOwners,
  graphicDataInicial,
  setGraphicDataInicial,
  graphicDataFinal,
  setGraphicDataFinal,
  graphicData,
  getFinancialGraphicData
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div className="financial-general-information">
      <div className="financial-general-information-content">
        <div className="financial-general-information-first-content">
          <div className="financial-general-information-first-content__cards-container">
            <div className="financial-general-information-filter">
              <div className="financial-general-information-filter__title">
                <span>Filtrar por período</span>
                <BsFilter
                  className="financial-general-information-filter__icon"
                  aria-describedby={id}
                  size={20}
                  onClick={handleOpenPopper}
                />
              </div>
              <Popper
                id="id-periodo"
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                disablePortal
              >
                <Box>
                  <ClickAwayListener onClickAway={handleOpenPopper}>
                    <Paper variant="elevation" elevation={3}>
                      <div className="financial-general-information-filter-container">
                        <div className="financial-general-information-filter-date-container">
                          <div>
                            <label className="financial-general-information-filter-date-container__label">
                              Data Inicial:
                            </label>
                            <DatePicker
                              className="financial-general-information-filter-date-container__input-date"
                              selected={graphicDataInicial}
                              popperPlacement="bottom-end"
                              placeholderText="..."
                              maxDate={new Date()}
                              locale="ptBR"
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setGraphicDataInicial(date)}
                            />
                          </div>
                          <div>
                            <label className="financial-general-information-filter-date-container__label">
                              Data Final:
                            </label>
                            <DatePicker
                              className="financial-general-information-filter-date-container__input-date"
                              disabled={!graphicDataInicial}
                              selected={graphicDataFinal}
                              popperPlacement="bottom-start"
                              placeholderText="..."
                              maxDate={new Date()}
                              locale="ptBR"
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setGraphicDataFinal(date)}
                            />
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Box>
              </Popper>
            </div>
            <div className="financial-general-information-first-content__cards">
              <span className="financial-general-information-first-content__cards--label">
                Vitalício em {moment(new Date()).format("MM/YY")}
              </span>
              <span className="financial-general-information-first-content__cards--value">
                {graphicData && graphicData[graphicData.length - 1]?.vitalicioFormatted}
              </span>
            </div>
            <div className="financial-general-information-first-content__cards">
              <span className="financial-general-information-first-content__cards--label">
                Fatura em {moment(new Date()).format("MM/YY")}
              </span>
              <span className="financial-general-information-first-content__cards--value">
                {graphicData && graphicData[graphicData.length - 1]?.faturamentoFormatted}
              </span>
            </div>
            <div className="financial-general-information-first-content__cards">
              <span className="financial-general-information-first-content__cards--label">
                Agenciamento em {moment(new Date()).format("MM/YY")}
              </span>
              <span className="financial-general-information-first-content__cards--value">
                {graphicData && graphicData[graphicData.length - 1]?.agenciamentoFormatted}
              </span>
            </div>
          </div>
          <div className="financial-general-information-first-content__card-top-10-container">
            <FinancialGraphic
              graphicData={graphicData}
              getFinancialGraphicData={getFinancialGraphicData}
              graphicDataFinal={graphicDataFinal}
            />
            <Top10PoliciesOwnersLifetime
              lifetimePolicyOwners={lifetimePolicyOwners}
              graphicDataInicial={graphicDataInicial}
              graphicDataFinal={graphicDataFinal}
            />
          </div>
        </div>
        <div className="financial-general-information-veritical-bar"></div>
        <div className="financial-general-information-second-content">
          <div className="financial-general-information-second-content__cards">
            <span className="financial-general-information-second-content__cards--label">
              Vitalício no ano
            </span>
            <span className="financial-general-information-second-content__cards--value">
              {graphicData && Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(graphicData.reduce((acc, data) => acc + data.vitalicio, 0))}
            </span>
          </div>
          <div className="financial-general-information-second-content__cards">
            <span className="financial-general-information-second-content__cards--label">
              Agenciamento no ano
            </span>
            <span className="financial-general-information-second-content__cards--value">
              {graphicData && Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(graphicData.reduce((acc, data) => acc + data.agenciamento, 0))}
            </span>
          </div>
          <div className="financial-general-information-second-content__cards">
            <span className="financial-general-information-second-content__cards--label">
              Fatura no ano
            </span>
            <span className="financial-general-information-second-content__cards--value">
              {graphicData && Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(graphicData.reduce((acc, data) => acc + data.faturamento, 0))}
            </span>
          </div>
          <div className="financial-general-information-second-content__cards">
            <span className="financial-general-information-second-content__cards--label">
              Vitalício por vida (mês atual)
            </span>
            <span className="financial-general-information-second-content__cards--value">
              {commissionMonth.lifetimePerBeneficiary}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialGeneralInformation;
