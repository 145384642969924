export const typeColumns = [
  {
    header: 'Estipulante/ Sub',
    key: 'estipulante_razao_social',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Nome',
    key: 'nome',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Email',
    key: 'email',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Telefone',
    key: 'telefone_celular',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Data de nascimento',
    key: 'data_nascimento',
    style: {
      numFmt: 'dd"/"mm"/"yyyy',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 30
  },
  {
    header: 'Grau Parentesco',
    key: 'grau_parentesco',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'CPF',
    key: 'cpf',
    style: {
      numFmt: '000"."000"."000"-"00',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 30
  },
  {
    header: 'Se dependente CPF do titular',
    key: 'cpf_titular',
    style: {
      numFmt: '000"."000"."000"-"00',
      alignment: { vertical: 'middle', horizontal: 'center' }
    },
    height: 25,
    width: 40
  },
  {
    header: 'Seguradora saúde',
    key: 'seguradora_saude',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Número da apólice saúde',
    key: 'numero_apolice_saude',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Plano saúde',
    key: 'plano_saude',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Status do plano saúde',
    key: 'status_plano_saude',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Número da carteirinha saúde',
    key: 'numero_carteirinha_saude',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Data de entrada saúde',
    key: 'data_entrada_saude',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Data de exclusão saúde',
    key: 'data_exclusao_saude',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Seguradora dental',
    key: 'seguradora_odonto',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Número da apólice dental',
    key: 'numero_apolice_odonto',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Plano dental',
    key: 'plano_odonto',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Status do plano dental',
    key: 'status_plano_odonto',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Número da carteirinha dental',
    key: 'numero_carteirinha_odonto',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Data de entrada dental',
    key: 'data_entrada_odonto',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  },
  {
    header: 'Data de exclusão dental',
    key: 'data_exclusao_odonto',
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
    height: 25,
    width: 30
  }
];
